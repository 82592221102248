<!-- 消费记录查询 -->
<template>
  <div>
    <div class="tool">
      <div>
        <a-select v-model:value="field" @change="onFieldChange">
          <a-select-option value="username">用户名</a-select-option>
        </a-select>
        <a-input-search
          v-model:value="keyword"
          placeholder="请输入关键词"
          enter-button
          @search="onSearch"
          @change.stop="onSearchChange"
        />
      </div>
    </div>
    <div style="margin-top: 6px">
      <a-table
        :scroll="{ x: 1000 }"
        :rowKey="(record) => record.id"
        :pagination="false"
        class="ant-table-striped"
        size="middle"
        :loading="loading"
        bordered
        :columns="columns"
        :data-source="consumeInfoList"
        :rowClassName="
          (record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
      >
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="pagination.pageIndex"
          :pageSize="pagination.pageSize"
          :total="pagination.total"
          :page-size-options="pagination.pageSizeOptions"
          :showTotal="(total) => `共 ${total} 条记录`"
          show-size-changer
          @change="onChangePage"
          @showSizeChange="onShowSizeChange"
        >
          <template #buildOptionText="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, defineComponent } from "vue";
import { getConsumeListPage } from "@/api/message/send";
import moment from "moment";
export default defineComponent({
  name: "Consume",
  components: {},
  setup() {
    const state = reactive({
      field: "username",
      keyword: "",
      userinfo: JSON.parse(sessionStorage.getItem("userinfo")),
      rid: JSON.parse(sessionStorage.getItem("userinfo")).roles[0].id,
      searchParam: {
        searchField: "",
        searchValue: "",
        pageIndex: 1,
        pageSize: 10,
        rid: JSON.parse(sessionStorage.getItem("userinfo")).roles[0].id,
      },
      consumeInfoList: [],
      pagination: {
        pageIndex: 1, // 默认显示第1页
        pageSize: 10, // 默认每页显示数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        total: 0, //总条数
      },
    });
    const columns = [
      {
        title: "账户名称",
        dataIndex: "accountInfo.uname",
        key: "accountName",
        width: "20%",
      },
      {
        title: "任务批次",
        dataIndex: "taskId",
        key: "taskId",
        align: "center",
        width: "20%",
      },
      {
        title: "消费数量",
        dataIndex: "successCounts",
        key: "successCounts",
        align: "center",
        width: "20%",
      },
      {
        title: "账户余额",
        dataIndex: "amount",
        key: "amount",
        align: "center",
        width: "20%",
      },
      {
        title: "消费时间",
        dataIndex: "createDate",
        key: "createDate",
        align: "center",
        width: "20%",
        sorter: (a, b) => (a.createDate < b.createDate ? 1 : -1),
        customRender: ({ text }) => {
          return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "";
        },
      },
    ];
    onMounted(() => {
      if (state.searchValue) {
        state.searchParam.searchField = state.field;
        state.searchParam.searchValue = state.keyword;
        state.searchParam.rid = state.userinfo.roles[0].id;
      }
      initConsumeInfoList(state.searchParam);
    });
    const initConsumeInfoList = (searchParam) => {
      state.loading = true;
      getConsumeListPage(searchParam).then((resp) => {
        if (resp) {
          state.consumeInfoList = resp.obj.list;
          state.pagination.total = resp.obj.pagination.total;
          state.loading = false;
        }
      });
    };
    const onSearch = () => {
      state.searchParam = {
        searchField: state.field,
        searchValue: state.keyword,
        pageIndex: 1,
        pageSize: 10,
        rid: state.userinfo.roles[0].id,
      };
      initConsumeInfoList(state.searchParam);
    };
    const onSearchChange = () => {
      state.searchParam.searchValue = state.keyword;
      state.searchParam.searchField = state.field;
    };
    const onFieldChange = (value) => {
      state.field = value;
      state.searchParam.searchField = state.field;
    };
    const onChangePage = (page, pageSize) => {
      if (state.keyword) {
        state.searchParam.searchField = state.field;
        state.searchParam.searchValue = state.keyword;
      }
      state.searchParam.rid = state.userinfo.roles[0].id;
      state.pagination.pageIndex = page;
      state.searchParam.pageIndex = page;
      state.searchParam.pageSize = pageSize;
      initConsumeInfoList(state.searchParam);
    };
    const onShowSizeChange = (page, pageSize) => {
      if (state.keyword) {
        state.searchParam.searchField = state.field;
        state.searchParam.searchValue = state.keyword;
      }
      state.searchParam.rid = state.userinfo.roles[0].id;
      state.pagination.pageSize = pageSize;
      state.pagination.pageIndex = 1;
      state.searchParam.pageIndex = 1;
      state.searchParam.pageSize = pageSize;
      initConsumeInfoList(state.searchParam);
    };
    const refData = toRefs(state);
    return {
      ...refData,
      columns,
      initConsumeInfoList,
      onSearch,
      onSearchChange,
      onFieldChange,
      onChangePage,
      onShowSizeChange,
    };
  },
});
</script>
<style lang='scss' scoped>
.searchAreaCss {
  border: 1px solid #409eff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0px;
}

.ant-input-search {
  width: 300px;
  margin-right: 5px;
}
.pagination {
  margin: 6px 0px;
  padding: 6px;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(183, 217, 241, 0.5);
}
</style>